import { inject } from 'vue';
import type { UseLayoutInjection } from '~/components/layout/types';
import { LAYOUT_INJECTION_KEY } from '~/components/layout/constants';

export interface UseLayoutSettings {
  /**
   * Merge the default layout classes. The passed classes will override existing classes.
   */
  defaultClasses?: string;
}

export function useLayout(settings: UseLayoutSettings = {}) {
  const injection = inject<UseLayoutInjection | null>(
    LAYOUT_INJECTION_KEY,
    null,
  );

  if (injection === null) {
    throw new Error('Cannot use useLayout outside <LayoutProvider> component.');
  }

  const { defaultClasses = '' } = settings;

  if (defaultClasses) {
    injection.mergeWithDefaultClasses(defaultClasses);
  }

  return {
    drawer: injection.drawer,
    toggleDrawer: injection.toggleDrawer,
    defaultClasses: injection.defaultClasses,
    isSupportedWidth: injection.isSupportedWidth,
    minSupportedWidth: injection.minSupportedWidth,
  };
}
